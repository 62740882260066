//import {Html5QrcodeScanner} from "html5-qrcode.min";
console.info("Loaded myScipt.js");
	var theDataSource = "";
	var myTimeInt = false;

	anon();

	var dataSetter = function() {
		this.init= function() {
			console.info('Data Setter initialized');
			//waitDataTable();

		}
		
		this.setDataTable = function(obj,msg = ""){
			if(msg){console.warn(msg,obj);}
			//theDataSource = obj;
			anon();
		}

	}

	var myTimer;
	
	function anon(){
		//console.info("Loaded - myScipt.js");
		console.warn("called from auto.............");
		myTimer = setInterval(waitDataTable,1000);
	};
	
	//Check if the table exist
	//and wait for data loaded on table
	function waitDataTable(){
		console.warn('waitDataTable Called');
		let id = "#dataTable";
		let target = $(document).find(id);
		let tr = $(target).find('tr');
		
		if($(tr).length>=2){
			//set dataTable on data Loaded
			if(!$(id).is('.initiate')){
				$(id).addClass('initiate');
				
				if($(target).is('.hasFilter')){
					myFilterSetter(id);	
				}
				setDataTable(id);
			}else{
				//console.warn('Already Initiate', "STOP setting DataTable");
				
			}
			clearInterval(myTimer);
		}
		//clearInterval(myTimer);
	}
	
/*********************************************************************************/	
	
	function setDataTable(id,parms = ""){
		let target = $(document).find(id);
		console.info("Datatable Initate");
		 table = $(target).DataTable({
			search: {
           	 return: false,
        	},
			/*order: [[0, 'desc']],*/
			paging: true,
			ordering: true,
			info: true,
			language: {
				lengthMenu: 'Afficher _MENU_ lignes par page',
				zeroRecords: 'Ligne introuvable',
				info: 'Affichage de _PAGE_ sur _PAGES_ pages',
				infoEmpty: 'Aucun résultat',
				infoFiltered: '(filter sur _MAX_ lignes)',
				search: 'Recherche rapide'
				
        	},
			initComplete: function(){
				// Apply the search
					this.api().columns().every(function(index){
						let that = this;
						 let toggler = $('.toggler');
						//let hidden = inLs[index]? !inLs[index].visible ? "hidded":"":"";
						
						//toggler.append('<a class="toggle-vis '+hidden+'" data-column="'+index+'">'+$(that.header()).text()+'</a>');
						//if input search {
							$('input', this.header()).on('keyup change clear', function(e){
							
								if (that.search()!== this.value) {
									that.search( this.value )
										.draw();
								}
							});
						//} //end iput Search 
						
						
						/*//if select used { */
						let select = $('<select><option value=""></option></select>')
								//.appendTo($(that.header().empty())
								.appendTo($(that.header()).not('.ignore-filter'))
								   .on( 'change', function (){
									let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								   );
	 
							that.search( val ? '^'+val+'$' : '', true, false)
								.draw();
						});
	 
						that.data().unique().sort().each(function(data,index){
							select.append('<option value="'+data+'">'+data+'</option>')
						 });
						// } ens select Search 
						
						$('input,select').on('click',function(e){
							e.stopPropagation();
						});
						
					});
					
					//console.info(this);
				},
			

		});
	}

/********************************************************************************/
